// 引入
import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router/router';
//导入elementUi组件相关样式
import 'element-ui/lib/theme-chalk/index.css';
//导入elementUi组件库
import ElementUI from 'element-ui';

import BaiduMap from 'vue-baidu-map';

//配置Vue插件 将El安装到Vue上
Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'oKf2zRdCVrVu0G839qivYXxSrLEATHkm', //公司的ak
});

// 创建vue实例
new Vue({
  router,
  el: '#app',
  render: (h) => h(App),
});
